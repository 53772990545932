import Vue from "vue";
import VueRouter from "vue-router";

// views
import MultiRunView from "@/views/MultiRunView.vue";
import SitesView from "@/views/SitesView.vue";
import ScenarioView from "@/views/ScenarioView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "multi_run",
    path: "/",
    components: {
      default: MultiRunView
    }
  },
  {
    name: "scenario",
    path: "/scenario",
    components: {
      default: ScenarioView
    }
  },
  {
    name: "mapsites",
    path: "/sites",
    components: {
      default: SitesView
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
