import Vue from "vue";

// plugins
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/main.css";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

import '@aws-amplify/ui-vue';

import App from './App.vue';
import router from "./router";

Vue.config.productionTip = false;

// set api url for requests
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$http = axios;

// register components
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");