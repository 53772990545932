<template>
  <div class="value_box">
    <div class="header"><slot name="main"></slot></div>
    <hr class="hr" />
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueBox",
  props: ["footer"],
};
</script>

<style scoped>
.value_box {
  background: rgb(9, 27, 44);
  background: linear-gradient(
    45deg,
    rgba(9, 27, 44, 1) 0%,
    rgba(0, 33, 70, 1) 75%,
    rgb(0, 49, 105) 100%
  );
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.header {
  margin-left: 5px;
}

.hr {
  margin-bottom: 0;
  margin-top: 0;
  border-color: rgb(230, 230, 230);
}

.footer {
  margin-bottom: 0;
  padding: 2px;
  text-align: right;
}
</style>
