<template>
  <div id="map-container">
    <div id="menu" />
    <div id="map" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  props: {
    geosites: { default: null },
    prothena: { default: null },
    jansson: { default: null },
    sw: {
      default: function () {
        return [-180, -85];
      },
    },
    ne: {
      default: function () {
        return [180, 85];
      },
    },
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1Ijoid29vZGxvY2stZGF2aWQiLCJhIjoiY2tteGIzMHJ3MGxpMzJvbXRhaG9yZjNibiJ9.XHQxCnA4lIPw86aya1T-HQ", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/light-v10", // your map style
      map: null,
    };
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = mapboxgl;
  },
  mounted() {
    var v1 = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(this.sw[0], this.sw[1]),
      new mapboxgl.LngLat(this.ne[0], this.ne[1])
    );
    mapboxgl.accessToken =
      "pk.eyJ1Ijoid29vZGxvY2stZGF2aWQiLCJhIjoiY2tteGIzMHJ3MGxpMzJvbXRhaG9yZjNibiJ9.XHQxCnA4lIPw86aya1T-HQ";
    let map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      zoom: 1,
      center: [40, 20],
      minZoom: 1.2,
      maxBounds: v1,
    });
    this.map = map;

    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.on("load", () => {
      map.addSource("Caelum", {
        type: "geojson",
        data: this.geosites,
      });
      map.addSource("Jansson", {
        type: "geojson",
        data: this.jansson,
      });
      map.addSource("Prothena", {
        type: "geojson",
        data: this.prothena,
      });

      // Add a layer showing the Caelum sites.
      map.addLayer({
        id: "Caelum",
        type: "circle",
        source: "Caelum",
        layout: {
          visibility: "visible",
        },
        paint: {
          "circle-color": "#4264fb",
          "circle-radius": 8,
          "circle-stroke-width": 0.5,
          "circle-stroke-color": "#ffffff",
        },
      });
      // Add a layer showing the Prothena sites.
      map.addLayer({
        id: "Prothena",
        type: "circle",
        source: "Prothena",
        layout: {
          visibility: "visible",
        },
        paint: {
          "circle-color": "#FFC107",
          "circle-radius": 8,
          "circle-stroke-width": 0.5,
          "circle-stroke-color": "#ffffff",
        },
      });
      // Add a layer showing the Jansson sites.
      map.addLayer({
        id: "Jansson",
        type: "circle",
        source: "Jansson",
        layout: {
          visibility: "visible",
        },
        paint: {
          "circle-color": "#004D40",
          "circle-radius": 8,
          "circle-stroke-width": 0.5,
          "circle-stroke-color": "#ffffff",
        },
      });

      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      //show popup when mouse enters (hovers) over a site: starting with Caelum layer
      map.on("mouseenter", "Caelum", function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        var coordinates = e.features[0].geometry.coordinates.slice();
        var description =
          "Site ID: " +
          e.features[0].properties.id +
          "<br>" +
          "Site name: " +
          e.features[0].properties.name +
          "<br>" +
          "Site status: " +
          e.features[0].properties.status +
          "<br>" +
          "No. 301 patients = " +
          e.features[0].properties.pt_301 +
          "<br>" +
          "No. 302 patients = " +
          e.features[0].properties.pt_302;
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });
      //then do the same foe Prothena and Jansson layers
      map.on("mouseenter", "Prothena", function (e) {
        map.getCanvas().style.cursor = "pointer";
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = "Site name: " + e.features[0].properties.name;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });
      map.on("mouseenter", "Jansson", function (e) {
        map.getCanvas().style.cursor = "pointer";

        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = "Site name: " + e.features[0].properties.name;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      // closes popup when mouse leaves the point for every layer
      map.on("mouseleave", "Jansson", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
      map.on("mouseleave", "Prothena", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
      map.on("mouseleave", "Caelum", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });

    var toggleableLayerIds = ["Caelum", "Jansson", "Prothena"];
    for (var i = 0; i < toggleableLayerIds.length; i++) {
      var id = toggleableLayerIds[i];
      if (!document.getElementById(id)) {
        // Create a link.
        var link = document.createElement("a");
        link.id = id;
        link.href = "#";
        link.textContent = id;
        link.className = "active";
        // Show or hide layer when the toggle is clicked.
        link.onclick = function (e) {
          var clickedLayer = this.textContent;
          e.preventDefault();
          e.stopPropagation();

          var visibility = map.getLayoutProperty(clickedLayer, "visibility");

          // Toggle layer visibility by changing the layout object's visibility property.
          if (visibility === "visible") {
            map.setLayoutProperty(clickedLayer, "visibility", "none");
            this.className = "";
          } else {
            this.className = "active";
            map.setLayoutProperty(clickedLayer, "visibility", "visible");
          }
        };
        var layers = document.getElementById("menu");
        layers.appendChild(link);
      }
    }
  },
};
</script>

<style>
@import url("https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css");
@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.45.0/mapbox-gl.css");

#map-container {
  width: 100%;
  height: 700px;
  position: relative;
}
#map {
  width: 100%;
  height: 700px;
}
#menu {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  background: #fff;
  border-radius: 3px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
}

#menu a {
  font-size: 13px;
  color: #404040;
  display: block;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

#menu a:last-child {
  border: none;
}

#menu a:hover {
  background-color: #f8f8f8;
  color: #404040;
}

#menu a.active {
  background-color: #091b2c;
  color: #ffffff;
}

#menu a.active:hover {
  background: #3074a4;
}
</style>
