<template>
  <div v-if="isLoading" class="text-center">
    <b-spinner label="Spinning" class="spinner"></b-spinner>
  </div>
  <div v-else>
    <apexchart
      type="line"
      :height="height"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

 <script>
export default {
  name: "barandline",
  props: {
    series: Array,
    categories: Array,
    height: { type: Number, default: 400 },
    intercept: { type: Number, default: null },
    yaxistitle: { type: String, default: "Patients Enrolled" },
    colors: {
      type: Array,
      default: function () {
        return ["#091b2c", "#005AB5", "#df3531", "#df3531"];
      },
    },
  },
  computed: {
    isLoading: function () {
      return this.series.length === 0;
    },
    chartOptions: function () {
      return {
        colors: this.colors,
        chart: {
          animations: {
            enabled: false,
          },
          fontFamily:
            "Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif",
        },
        title: {
          text: "",
        },
        stroke: { width: 1 },
        labels: this.categories,
        annotations: {
          xaxis: [
            {
              x: new Date().setHours(0, 0, 0, 0),
              strokeDashArray: 0,
              borderColor: "black",
              label: {
                text: "Today",
                style: {
                  colors: "black",
                  fontSize: "14",
                },
              },
            },
          ],
          yaxis: [
            {
              y: this.intercept,
              borderColor: "#091b2c",
              strokeDashArray: 3,
              label: {
                position: "left",
                textAnchor: "start",
                offsetX: 10,
                offsetY: 8,
                style: {
                  colors: "black",
                  fontSize: "18",
                },
                text: `Target: ${this.intercept}`,
              },
            },
          ],
        },
        xaxis: {
          type: "datetime",
          labels: {
            style: {
              colors: "black",
              fontSize: "14",
            },
            offsetY: -3,
          },
          axisBorder: {
            color: "black",
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            decimalsInFloat: 0,
            title: {
              text: this.yaxistitle,
              style: {
                colors: "black",
                fontSize: "20",
                fontWeight: "100",
              },
              offsetX: -10,
            },
            labels: {
              style: {
                colors: "black",
                fontSize: "14",
              },
              offsetY: -3,
              formatter: (value) => {
                if (!value) {
                  return null;
                }
                const formatter = new Intl.NumberFormat("en", {});
                return formatter.format(value.toFixed(0));
              },
            },
          },
        ],
        tooltip: {
          x: {
            format: "MMM yyyy",
          },
        },
        legend: {
          show: true,
        },
        markers: {
          size: 4,
        },
      };
    },
  },
};
</script>