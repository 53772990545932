<template>
  <b-container class="bv-example-row" fluid>
    <b-form-row>
      <b-col>
        <div style="display: flex; align-items: center; margin-left: 5px">
          <div style="float: left; font-size: 25px">Select a study:</div>
          <div class="container-selector">
            <b-list-group horizontal>
              <b-list-group-item
                class="selector"
                v-for="study in studyOptions"
                :key="study.key"
                v-bind:class="{ active: isActiveStudy(study.key) }"
                @click="setActiveStudy(study.key)"
              >
                {{ study.name }}
                <div class="sub" v-if="study.sub">{{ study.sub }}</div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-col>
    </b-form-row>
    <hr style="margin-bottom: 0.25rem; margin-top: 0.5rem" />
    <div class="plot-title-container">
      <div class="plot-title">
        Probabilistic Enrolment Prediction for CAEL101-{{ selectedStudy }}
      </div>
      <div class="plot-subtitle">
        CAEL101-{{ selectedStudy }} is projected to hit the target enrolment
        {{
          lateAchievement === "Past horizon"
            ? `after ${earlyAchievement}`
            : `between ${earlyAchievement} and ${lateAchievement}`
        }}. Enrolment is forecast out to
        {{ lastForecast }}
      </div>
    </div>
    <b-row>
      <b-col cols="12" lg="3">
        <div class="scenario-selector">
          <div role="tablist">
            <b-card
              v-for="scenario in scenarioOptions"
              :key="scenario.key"
              no-body
            >
              <b-card-header
                header-tag="header"
                class="selector"
                role="tab"
                v-bind:class="{ active: isActiveScenario(scenario.key) }"
                @click="setActiveScenario(scenario.key)"
              >
                <div block>
                  {{ scenario.name }}
                </div>
              </b-card-header>
              <b-collapse
                :visible="selectedScenario == scenario.key"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>{{ scenario.sub }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="9">
        <!-- Boxes -->
        <b-form-row>
          <b-col cols="12" sm="6" md="4">
            <ValueBox>
              <div slot="main">
                <div class="value">
                  {{
                    earlyDelta > 0 || earlyDelta == "-"
                      ? earlyDelta
                      : -earlyDelta
                  }}
                  months {{ earlyDelta > 0 ? "after" : "before" }} base
                </div>
                <div class="subheader">
                  {{ earlyAchievement }}
                </div>
              </div>
              <div slot="footer">
                <div class="subheader">
                  Earliest Projected Target Achievement
                </div>
              </div>
            </ValueBox>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <ValueBox>
              <div slot="main">
                <div class="value">
                  {{
                    meanDelta > 0 || meanDelta == "-" ? meanDelta : -meanDelta
                  }}
                  months {{ meanDelta > 0 ? "after" : "before" }} base
                </div>
                <div class="subheader">
                  {{ meanAchievement }}
                </div>
              </div>
              <div slot="footer">
                <div class="subheader">
                  Most Likely Projected Target Achievement
                </div>
              </div>
            </ValueBox>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <ValueBox>
              <div slot="main">
                <div class="value">
                  {{
                    lateDelta > 0 || lateDelta == "-" ? lateDelta : -lateDelta
                  }}
                  months {{ lateDelta > 0 ? "after" : "before" }} base
                </div>
                <div class="subheader">
                  {{ lateAchievement }}
                </div>
              </div>
              <div slot="footer">
                <div class="subheader">Latest Projected Target Achievement</div>
              </div>
            </ValueBox>
          </b-col>
        </b-form-row>
        <!-- Plot -->
        <b-row>
          <b-col>
            <div class="plot-container">
              <LineChart
                :series="enrollment"
                :categories="enrollmentDates"
                :height="plotHeight"
                :intercept="targetEnrollment"
                yaxistitle="Patients Enrolled"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Auth } from "aws-amplify";
import LineChart from "@/components/LineChart.vue";
import ValueBox from "@/components/ValueBox.vue";

export default {
  name: "scenario-view",
  components: {
    LineChart,
    ValueBox,
  },
  data() {
    return {
      studyOptions: [
        { name: "CAEL101-301", key: "301", sub: null },
        { name: "CAEL101-302", key: "302", sub: null },
      ],
      selectedStudy: "301",
      scenarioOptions: [
        {
          name: "Minor COVID Impact",
          key: "scenario_1",
          sub:
            "COVID is assumed to depress enrolment by a factor of 0.7 in countries with predicted doubling of cases over the next 45 days",
        },
        {
          name: "Major COVID Impact",
          key: "scenario_2",
          sub:
            "COVID is assumed to depress enrolment by a factor of 0.7 in countries with any predicted rise in cases over the next 45 days",
        },
        {
          name: "Decreased Site Activity",
          key: "scenario_3",
          sub:
            "Sites are modelled to recruit 10% fewer patients",
        },
        {
          name: "Increased Site Activity",
          key: "scenario_4",
          sub:
            "Sites are modelled to recruit 10% more patients",
        },
        {
          name: "Increased Screen Failure",
          key: "scenario_5",
          sub:
            "Screen failure modelled to be 10% higher than base case",
        },
        {
          name: "10 Additional Sites",
          key: "scenario_6",
          sub:
            "Additional 10 sites are modelled to be activated between 4Q21 and 1Q22",
        },
        {
          name: "20 Additional Sites",
          key: "scenario_7",
          sub:
            "Additional 20 sites are modelled to be activated around 4Q21 and 1Q22",
        },
        {
          name: "30 Additional Sites",
          key: "scenario_8",
          sub:
            "Additional 30 sites are modelled to be activated around 4Q21 and 1Q22",
        },
      ],
      selectedScenario: "scenario_1",
      targetEnrollment: null,
      //
      enrollment: [],
      enrollmentDates: [],
      //
      meanAchievement: "-",
      lateAchievement: "-",
      earlyAchievement: "-",
      meanDelta: "-",
      lateDelta: "-",
      earlyDelta: "-",
      //
      timer: "",
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  computed: {
    plotHeight() {
      return Math.max(this.windowHeight - 350, 350);
    },
    lastForecast() {
      if (this.enrollmentDates.length < 1) {
        return "-";
      }
      var end = new Date(this.enrollmentDates[this.enrollmentDates.length - 1]);
      var dateFormat = require("dateformat");

      return dateFormat(end, "dd mmm yyyy");
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    isActiveStudy(key) {
      return this.selectedStudy == key;
    },
    setActiveStudy(key) {
      if (key !== this.selectedStudy) {
        this.selectedStudy = key;
        this.resetData();
        this.fetchData();
      }
    },
    isActiveScenario(key) {
      return this.selectedScenario == key;
    },
    setActiveScenario(key) {
      if (key !== this.selectedScenario) {
        this.selectedScenario = key;
        this.resetData();
        this.fetchData();
      }
    },
    resetData() {
      this.enrollment = [];
      this.enrollmentDates = [];
      this.meanAchievement = "-";
      this.lateAchievement = "-";
      this.earlyAchievement = "-";
      this.meanDelta = "-";
      this.lateDelta = "-";
      this.earlyDelta = "-";
    },
    fetchData() {
      this.fetchMultiRun();
      this.fetchTarget();
      this.fetchAchievement();
    },
    async fetchMultiRun() {
      this.$http
        .get(`/api/v1/sourcedata/scenario`, {
          params: {
            study: this.selectedStudy,
            scenario: this.selectedScenario,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.enrollmentDates = response.data.categories;
          this.enrollment = response.data.data;
        })
        .catch(() => {
          console.log("Error");
        });
    },
    async fetchTarget() {
      this.$http
        .get(`/api/v1/sourcedata/target`, {
          params: {
            study: this.selectedStudy,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.targetEnrollment = response.data.value;
        })
        .catch(() => {
          console.log("Error");
        });
    },
    async fetchAchievement() {
      this.$http
        .get(`/api/v1/sourcedata/scenario_achievement`, {
          params: {
            study: this.selectedStudy,
            scenario: this.selectedScenario,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.meanAchievement = response.data.mean;
          this.lateAchievement = response.data.lower;
          this.earlyAchievement = response.data.upper;
          this.meanDelta = response.data.mean_delta;
          this.lateDelta = response.data.lower_delta;
          this.earlyDelta = response.data.upper_delta;
        })
        .catch(() => {
          console.log("Error");
        });
    },
  },
};
</script>


<style>
.list-group {
  border-radius: 0.75rem;
}
.list-group-item {
  background-color: #f9f9f9;
}
.container-selector {
  margin-left: 10px;
}
.selector {
  font-size: 1.25rem;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.selector:hover {
  background-color: #eaeaea;
  color: black;
  cursor: pointer;
}
.selector.active {
  background-color: #091b2c;
  color: white;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.scenario-selector {
  margin-top: 10px;
}

.scenario-title-container {
  margin: 2px 0;
}
.plot-title-container {
  padding: 5px;
}
.sub {
  font-size: 0.5rem;
  position: absolute;
}
.value {
  font-size: 2vw;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subheader {
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>