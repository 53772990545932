<template>
  <amplify-authenticator>
    <div id="app">
      <nav class="main-nav">
        <div class="navbar-nav mr-auto" style="justify-content: center">
          <div style="display: flex; align-items: center" class="logo">
            <img
              src="./assets/Icon-Color.png"
              style="height: 40px; padding-right: 20px; vertical-align: middle"
            />
            <div class="d-none d-sm-inline-block">Caelum Enrolment</div>
          </div>
        </div>
        <div class="navbar-nav ml-auto">
          <img
            src="./assets/ADL.png"
            style="height: 40px; padding-right: 20px"
          />
        </div>
        <Burger></Burger>
      </nav>

      <Sidebar></Sidebar>

      <div class="body-container">
        <router-view class="view one"></router-view>
      </div>
    </div>
  </amplify-authenticator>
</template>

<script>
import Burger from "@/components/Menu/Burger.vue";
import Sidebar from "@/components/Menu/Sidebar.vue";

export default {
  name: "app",
  components: { Burger, Sidebar },
};
</script>

<style>
.logo {
  align-self: center;
  color: #fff;
  font-weight: 300;
  font-size: 24px;
}
.main-nav {
  background-color: #091b2c;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
}

.body-container {
  width: 95vw;
  margin: auto;
  padding-top: 10px;
}
</style>
