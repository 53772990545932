<template>
  <b-sidebar
    id="sidebar-1"
    shadow
    no-header
    backdrop-variant="dark"
    backdrop
    right
  >
    <template #default="{ hide }">
      <div class="px-3 py-2">
        <div id="list-container">
          <ul class="sidebar-panel-nav">
            <li v-for="view in views" :key="view.name">
              <router-link v-on:click.native="hide" :to="view.path">
                <div
                  class="selection-container"
                  v-bind:class="isCurrentRoute(view.path)"
                >
                  {{ view.name }}
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="sign-out-container">
        <amplify-sign-out />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      views: [
        { path: "/", name: "Enrolment Prediction" },
        { path: "/scenario", name: "Enrolment Scenarios" },
        { path: "/sites", name: "Sites View" },
      ],
    };
  },
  methods: {
    isCurrentRoute(value) {
      if (this.$route.path === value) {
        return "current_route";
      }
    },
  },
};
</script>

<style scoped>
#list-container {
  margin-top: 10px;
}

ul.sidebar-panel-nav {
  list-style-type: none;
  padding-inline-start: 15px;
}
ul.sidebar-panel-nav > li > a {
  text-decoration: none;
  display: block;
}
.selection-container {
  color: #909090;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 15px 10px;
  border-bottom: 1px solid #909090;
}
.selection-container:hover {
  color: rgb(51 51 51);
}
.current_route {
  color: black;
  margin-top: -1px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

hr {
  background-color: #828282;
  margin: 0;
}

.sign-out-container {
  position: absolute;
  bottom: 25px;
  width: 100%;
}
</style>
