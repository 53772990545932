<template>
  <b-container class="bv-example-row" fluid>
    <b-row>
      <b-col>
        <div class="plot-container">
          <div class="plot-title">Caelum Sites</div>
          <hr />
          <div>
            <medium>
              <p>
                The map shows the CAEL101-301/302 sites in addition to the Janssen Daratumumab study and the Prothena VITAL Amyloidosis study sites.<br />
              </p>
            </medium>
            <small
              ><p>
                Use the mouse wheel to zoom in and out, or the buttons on the
                top right. Click and Drag to move around.<br />
                You can view additional Caelum sites details by hovering the
                mouse over the site. A pop-up window will show sites names and
                number of patients enrolled, amongst other details.<br />
                You can also get the competitor sites' name by hovering the
                mouse over the sites.<br />
                Note: You can hide/show any trial layer by toggling the menu
                buttons on/off shown at the top-right corner.
              </p></small
            >
          </div>
          <div v-if="geosites && jansson && prothena">
            <Map :geosites="geosites" :jansson="jansson" :prothena="prothena" />
          </div>
          <div v-else>
            <b-spinner label="Spinning" class="spinner"></b-spinner>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Auth } from "aws-amplify";
import Map from "@/components/Map.vue";

export default {
  name: "site-view",
  components: {
    Map,
  },
  data() {
    return {
      geosites: null,
      jansson: null,
      prothena: null,
    };
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchSites();
    },
    async fetchSites() {
      this.$http
        .get(`/api/v1/sourcedata/caelumsites`, {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.geosites = response.data.cael_sites;
          this.jansson = response.data.jnj_sites;
          this.prothena = response.data.prth_sites;
        })
        .catch(() => {
          console.log("Error");
        });
    },
  },
};
</script>
