<template>
  <b-container class="bv-example-row" fluid>
    <b-form-row>
      <b-col>
        <div style="display: flex; align-items: center; margin-left: 5px">
          <div style="float: left; font-size: 25px">Select a study:</div>
          <div class="container-selector">
            <b-list-group horizontal="sm">
              <b-list-group-item
                class="selector"
                v-for="study in studyOptions"
                :key="study.key"
                v-bind:class="{ active: isActive(study.key) }"
                @click="setActive(study.key)"
              >
                {{ study.name }}
                <div class="sub" v-if="study.sub">{{ study.sub }}</div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-col>
    </b-form-row>
    <hr style="margin-bottom: 0.25rem; margin-top: 0.5rem" />
    <div class="plot-title-container">
      <div class="plot-title">
        Probabilistic Enrolment Prediction for CAEL101-{{ selectedStudy }}
      </div>
      <div class="plot-subtitle">
        CAEL101-{{ selectedStudy }} is projected to hit the target enrolment
        {{
          lateAchievement === "Past horizon"
            ? `after ${earlyAchievement}`
            : `between ${earlyAchievement} and ${lateAchievement}`
        }}. Enrolment is forecast out to
        {{ lastForecast }}
      </div>
    </div>
    <!-- Boxes -->
    <b-form-row>
      <b-col cols="12" sm="6" md="4">
        <ValueBox>
          <div slot="main" class="value">{{ earlyAchievement }}</div>
          <div slot="footer">
            <div class="subheader">Earliest Projected Target Achievement</div>
          </div>
        </ValueBox>
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <ValueBox>
          <div slot="main" class="value">{{ meanAchievement }}</div>
          <div slot="footer">
            <div class="subheader">
              Most Likely Projected Target Achievement
            </div>
          </div>
        </ValueBox>
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <ValueBox>
          <div slot="main" class="value">{{ lateAchievement }}</div>
          <div slot="footer">
            <div class="subheader">Latest Projected Target Achievement</div>
          </div>
        </ValueBox>
      </b-col>
    </b-form-row>
    <!-- Plot -->
    <b-row>
      <b-col>
        <div class="plot-container">
          <LineChart
            :series="enrollment"
            :categories="enrollmentDates"
            :height="plotHeight"
            :intercept="targetEnrollment"
            yaxistitle="Patients Enrolled"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Auth } from "aws-amplify";
import LineChart from "@/components/LineChart.vue";
import ValueBox from "@/components/ValueBox.vue";

export default {
  name: "multi-run-view",
  components: {
    LineChart,
    ValueBox,
  },
  data() {
    return {
      studyOptions: [
        { name: "CAEL101-301", key: "301", sub: null },
        { name: "CAEL101-302", key: "302", sub: null },
      ],
      selectedStudy: "301",
      targetEnrollment: null,
      //
      enrollment: [],
      enrollmentDates: [],
      //
      meanAchievement: "-",
      lateAchievement: "-",
      earlyAchievement: "-",
      //
      timer: "",
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  computed: {
    plotHeight() {
      return Math.max(this.windowHeight - 350, 350);
    },
    lastForecast() {
      if (this.enrollmentDates.length < 1) {
        return "-";
      }

      var end = new Date(this.enrollmentDates[this.enrollmentDates.length - 1]);
      var dateFormat = require("dateformat");

      return dateFormat(end, "dd mmm yyyy");
    },
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    isActive(key) {
      return this.selectedStudy == key;
    },
    setActive(key) {
      if (key !== this.selectedStudy) {
        this.selectedStudy = key;
        this.fetchData();
      }
    },
    fetchData() {
      this.fetchMultiRun();
      this.fetchTarget();
      this.fetchAchievement();
    },
    async fetchMultiRun() {
      this.$http
        .get(`/api/v1/sourcedata/predictions`, {
          params: {
            study: this.selectedStudy,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.enrollmentDates = response.data.categories;
          this.enrollment = response.data.data;
        })
        .catch(() => {
          console.log("Error");
        });
    },
    async fetchTarget() {
      this.$http
        .get(`/api/v1/sourcedata/target`, {
          params: {
            study: this.selectedStudy,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.targetEnrollment = response.data.value;
        })
        .catch(() => {
          console.log("Error");
        });
    },
    async fetchAchievement() {
      this.$http
        .get(`/api/v1/sourcedata/target_achievement`, {
          params: {
            study: this.selectedStudy,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        })
        .then((response) => {
          this.meanAchievement = response.data.mean;
          this.lateAchievement = response.data.lower;
          this.earlyAchievement = response.data.upper;
        })
        .catch(() => {
          console.log("Error");
        });
    },
  },
};
</script>


<style>
.list-group {
  border-radius: 0.75rem;
}
.list-group-item {
  background-color: #f9f9f9;
}
.container-selector {
  margin-left: 10px;
}
.selector {
  font-size: 1.25rem;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.selector:hover {
  background-color: #eaeaea;
  color: black;
  cursor: pointer;
}
.selector.active {
  background-color: #091b2c;
  color: white;
  border: solid 1px rgba(0, 0, 0, 0.3);
}
.plot-title-container {
  padding: 5px;
}
.sub {
  font-size: 0.5rem;
  position: absolute;
}
.value {
  font-size: 40px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subheader {
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>