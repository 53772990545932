/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_DorAoYlYZ",
    "aws_user_pools_web_client_id": "5qi19d0q5s2kkbpd88lt6h9j84",
    "oauth": {}
};


export default awsmobile;
